import logo from './images/Cycling Tracker Logo.svg';
import bike from './images/Bike Illustration.svg';
import addedSound from './audio/goal_completion.mp3';
import datepicker from 'js-datepicker';
import {IconDelete, IconHome, IconBike,
  IconTimeline, IconPerson, IconTrendUp } from './Icons.js'
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import './App.css';
import React, { useState, useEffect, useContext } from 'react';

const AppContext = React.createContext({showAddedToast: null});
const AddedSound = new Audio(addedSound);

const months = [
  "January", "February", "March", "April", "May",
  "June", "July", "August", "September", "October",
  "November", "December"
];

function ButtonGroup(props) {
  return (
    <div className='ButtonGroup'>
      {props.children}
    </div>
  );
}

function Button(props) {
  return (
    <button className={"Button Button-" + props.color} onClick={props.onClick}>
      {props.label}
    </button>
  );
}

function DateSelectButton(props) {
  const sessionDateDisplay = props.date.getDate() + " " + months[props.date.getMonth()] + ", " + props.date.getFullYear();
  return (
    <button id="dateselect" className="DateSelectButton">
      {sessionDateDisplay}
    </button>
  );
}

function LinkText(props) {
  return (
    <a href={props.href} className={"LinkText LinkText-" + props.color}>{props.label}</a>
  );
}

function InputField(props){
  return (
    <div className="InputField">
      <label className="Input-label">
        {props.label}
        <input className="Input" type={props.inputType}>
        </input>
      </label>
    </div>
  );
}

function NavbarButton(props) {
  const active = props.active;
  return (
    <button onClick={() => props.onclick(props.page)} className={"NavbarButton" + (active ? " active": "")}>
      {props.icon}
    </button>
  );
}

function Navbar(props) {
  if (['landing', 'signup', 'login'].includes(props.page)){
    return null;
  }

  return (
    <div className='Navbar'>
      <NavbarButton page='home' icon={<IconHome />} active={props.page === 'home'} onclick={props.onclick}/>
      <NavbarButton page='track' icon={<IconBike />} active={props.page === 'track'} onclick={props.onclick}/>
      <NavbarButton page='history' icon={<IconTimeline />} active={props.page === 'history'} onclick={props.onclick}/>
      <NavbarButton page='profile' icon={<IconPerson />} active={props.page === 'profile'} onclick={props.onclick}/>
    </div>
  );
}

function Page(props) {

  useEffect(() => {
    const pageName = props["data-name"];

    gtag('event', 'page_view', {
      page_title: pageName.charAt(0).toUpperCase() + pageName.slice(1),
      page_location: '/',
      page_path: pageName,
    })
  })

  return (
    <div className={'Page' + (props.className ? ' ' + props.className: "") }>
      {props.children}
    </div>
  );
}

function LandingPage(props) {
  return (
    <Page color="white" data-name="landing">
      <img src={logo} className="App-logo" alt="logo" />
      <img src={bike} className="Bike-illustration" alt="illustration" />
      <ButtonGroup>
        <Button label="LOGIN" color="green"/>
        <Button label="SIGN UP" color="white"/>
      </ButtonGroup>
    </Page>
  );
}

function SignupPage(props) {
  return (
    <Page color="white" data-name="signup">
      <img src={bike} className="Bike-illustration-small" alt="illustration" />
      <form className='Form'>
        <InputField label="NAME" inputType="text"/>
        <InputField label="EMAIL" inputType="text"/>
        <InputField label="PASSWORD" inputType="password"/>
        <InputField label="CONFIRM PASSWORD" inputType="password"/>
      </form>
      <ButtonGroup>
        <Button label="SIGN UP" color="green" />
        <LinkText color="green" label="LOGIN?" href="/"/>
      </ButtonGroup>
    </Page>
  );
}

function LoginPage(props) {
  return (
    <Page color="white" data-name="login">
      <img src={bike} className="Bike-illustration-small" alt="illustration" />
      <form className='Form'>
        <InputField label="EMAIL" type="text" />
        <InputField label="PASSWORD" type="password" />
      </form>
      <ButtonGroup>
        <Button label="LOGIN" color="green"/>
        <LinkText color="green" label="NEW USER?" href="/"/>
      </ButtonGroup>
    </Page>
  );
}

function ProfilePage(props) {
  return (
    <Page color="grey" data-name="profile">
      <img src={bike}
           className="Bike-illustration-medium "
           alt="illustration" />
      {/* <Button label="LOGOUT" color="red" /> */}
    </Page>
  );
}

function Odometer(props) {
  const [distancePeriod, setDistancePeriod] = useState('WEEK');
  const periodDistances = props.periodDistances;

  function handlePeriodChange(e) {
    setDistancePeriod(e.target.value);
  }

  return (
    <div className='Odometer'>
      <p className='OdomemeterText'>
        YOU CYCLED
      </p>
      <div className='OdometerDisplay'>
        <p className='OdometerDisplayText'>{Math.round(periodDistances[distancePeriod])}</p>
      </div>
      <p className='OdometerSubscript'>
        KMs
      </p>
      <div className='OdometerSelectSection'>
        <p className='OdomemeterText'>THIS</p>
        <select className='PeriodSelect' value={distancePeriod} onChange={handlePeriodChange}>
          <option value="DAY">DAY</option>
          <option value="WEEK">WEEK</option>
          <option value="MONTH">MONTH</option>
          <option value="YEAR">YEAR</option>
          <option value="LIFE">LIFE</option>
        </select>
      </div>
    </div>
  );
}

function GraphSection(props) {
  let [selectedPeriod, setSelectedPeriod] = useState('last_7_days');
  let graphData = [];

  function handlePeriodChange(e) {
    selectedPeriod(e.target.value);
  }

  const session = getOrInitialiseStorage();

  switch (selectedPeriod) {
    case 'last_7_days':
      const weekDays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      for (var tickIds = [], tickLabels = [], i = 0, dt = new Date(); i < 7; i++, dt.setDate(dt.getDate() - 1)) {
        tickIds.push(
          dt.getFullYear() + '-' + (parseInt(dt.getMonth()) + 1) + '-' + dt.getDate()
        )
        tickLabels.push(
          weekDays[dt.getDay()]
        )
      }

      tickIds.reverse();
      tickLabels.reverse();
      
      let dataMap = tickIds.reduce((o, x) => ({...o, [x]: 0}), {})

      Object.values(session.data).forEach((point) => {
        if (tickIds.includes(point.date)) {
          dataMap[point.date] += point.distance
        }
      })

      tickIds.forEach((d, index) => {
        graphData.push(
          {
            'dataX': tickLabels[index],
            'distance': dataMap[d],
          }
        )
      })
      break;
    case 'last_6_weeks':
      break;
    case 'last_6_months':
      break;
  }

  return (
    <div className='GraphSection'>
      <div className='GraphParentContainer'>
        <ResponsiveContainer width="96%" height={"92%"}>
            <LineChart data={graphData}
            margin={{
              left: 15,
              right: 15,
              bottom: 5,
              top: 15,
            }}
            >
            <CartesianGrid strokeDasharray="2 2" stroke="grey"/>
            <YAxis
              orientation='right'
              type="number"
              axisLine={false}
              mirror={true}
              interval="preserveEnd"
              tickLine={false}
              tickMargin={0}
              tickFormatter={(v, i) => `${v === 0 ? "" : v}`}
            />
            <XAxis
              dataKey="dataX"
              stroke='#000'
              angle={-45}
              tickMargin={10}
              type='category'
              padding={{
                left: 0,
                right: 0
              }}
              tick={{fontSize: '0.75em'}}
              tickFormatter={(label) => label.slice(0,3)}
              interval='preserveStartEnd'
              ticks={tickLabels}
            />
            <Line
              type="monotone"
              dataKey="distance"
              stroke="#23ADA5"
              margin={{right: 10}}
              strokeWidth={3}
              unit=' km'
            />
            <Tooltip 
              contentStyle={{
                outline: 'none',
                border: '2px solid black',
                borderRadius: '6px'
              }}
              wrapperStyle={{
                outline: 'none',
                border: 'none'
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <select className='PeriodSelect' style={{float: 'right'}} onChange={handlePeriodChange}>
          <option value="last_7_days">Last 7 Days</option>
          {/* <option value="last_6_weeks">Last 6 Weeks</option>
          <option value="last_6_months">Last 6 Months</option> */}
        </select>
      </div>
    </div>
  )
}

function HomePage(props) {
  const periodDistances = {
    'DAY': 0,
    'WEEK': 0,
    'MONTH': 0,
    'YEAR': 0,
    'LIFE': 0,
  }

  const session = getOrInitialiseStorage();
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  const weekDates = [];

  for (let i = 0; i <= today.getDay(); i++) {
    const date = new Date(today);
    const new_date = today.getDate() - i;
    if (new_date < 0) {
      const new_month = today.getMonth() - 1;
      if (new_month < 0) {
        const new_year = today.getFullYear() - 1;
        date.setFullYear(new_year);
        date.setMonth(new_month);
        date.setDate(new_date)
      } else {
        date.setMonth(new_month);
      }
    } else {
      date.setDate(new_date);
    }
    weekDates.push(date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate());
  }

  Object.values(session.data).forEach((point) => {
    const date = new Date(point.date);
    const distance = point.distance;

    // Life stats
    periodDistances['LIFE'] += distance;

    // Year stats
    if (todayYear === date.getFullYear()) {
      periodDistances['YEAR'] += distance;
    } else {
      return;
    }

    // Month stats
    if (todayMonth === date.getMonth()) {
      periodDistances['MONTH'] += distance;
    } else {
      return;
    }

    // Week stats
    const date_str = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
    if (weekDates.includes(date_str)) {
      periodDistances['WEEK'] += point.distance;
    } else {
      return
    }

    // Day stats
    if (todayDate === date.getDate()) {
      periodDistances['DAY'] += distance
    }

  });

  
  return (
    <Page className="HomePage" data-name="home">
      <Odometer periodDistances={periodDistances} />
      <GraphSection />
      <div />
    </Page>
  );
}

function AddedToast(props) {
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (appContext.showAddedToast) {
      AddedSound.play()
      setTimeout(() => {
        appContext.setShowAddedToast(false);
      }, 2500);
    }
  }, [appContext.showAddedToast])

  function getClassNames() {
    if (appContext.showAddedToast === null) {
      return 'AddedToast-initial';
    } else {
      return 'AddedToast AddedToast-' + (appContext.showAddedToast ? 'show' : 'hide');
    }
  }

  return (
    <div className={getClassNames()}>
      <IconTrendUp />
      <p>Great work!</p>
    </div>
  );
}

function TrackPage(props) {
  const [sessionDate, setSessionState] = useState(new Date());
  const [sessionDistance, setSessionDistance] = useState('');
  const appContext = useContext(AppContext);

  const months = [
    "January", "February", "March", "April", "May",
    "June", "July", "August", "September", "October",
    "November", "December"
  ];

  function handleDistanceChange(e) {
    setSessionDistance(e.target.value);
  }

  function handleSave(e) {
    if (sessionDistance == 0) {
        return;
    }
    
    addDistance(
      sessionDate,
      parseFloat(sessionDistance)
    )
    setSessionState(new Date());
    setSessionDistance('');
    appContext.setShowAddedToast(true);
    appContext.setCurrentPage('home');
  }

  function handleCancel(e) {
    setSessionState(new Date());
    setSessionDistance('');
  }

  
  useEffect(() => {
    let picker = datepicker("#dateselect", {
      maxDate: new Date(),
      dateSelected: sessionDate,
      onSelect: (instance, date) => {
        let newDate = date || sessionDate;
        setSessionState(newDate);
      },
      customDays: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      disableYearOverlay: true,
      position: 'c'
    })
    picker.calendarContainer.style.setProperty('font-size', '1.25rem')

    return function cleanup() {
      picker.remove();
    };
  })

  return (
    <Page className="TrackPage" data-name="track">
      <div className='OdometerInput'>
        <p className='OdomemeterText'>
          YOU CYCLED
        </p>
        <input type="number" className="OdometerInputField" value={sessionDistance} onChange={handleDistanceChange}>
        </input>
        <hr className='OdometerInputField-underline'/>
        <p className='OdometerSubscript'>
          KMs
        </p>
        <div className='OdometerSelectSection'>
          <p className='OdomemeterText'>ON</p>
          <DateSelectButton date={sessionDate}/>
        </div>
      </div>
      <ButtonGroup>
        <Button label="SAVE" color="green" onClick={handleSave} />
        <Button label="CANCEL" color="red" onClick={handleCancel} />
      </ButtonGroup>
    </Page>
  );
}

function HistoryContainer(props) {
  return (
    <div className='HistoryContainer'>
      {props.children}
    </div>
  );
}

function HistoryItemContent(props) {
  return (
    <div className='HistoryItemContent'>
      {props.children}
    </div>
  );
}

function Marker(props) {
  return (
    <div className='Marker'/>
  );
}

function HistoryItem(props) {
  const [showDelete, setShowDelete] = useState(false);

  function onClick(e) {
    setShowDelete(!showDelete);
  }

  const showDeleteClass = showDelete ? 'show' : 'hide';
  return (
    <div className='HistoryItem'>
      <Marker />
      <HistoryItemContent>
        <div onClick={onClick}>
          <p>{props.day}</p>
          <p>{props.distance} KMs</p>
        </div>
        <div>
          <div className={'DeleteButton ' + 'DeleteButton-' + showDeleteClass}
            data-index={props.index}
            data-day={props.day}
            data-distance={props.distance}
            onClick={props.onClickDelete}>
            <IconDelete />
          </div>
        </div>
      </HistoryItemContent>
    </div>
  );
}

function HistoryScrollView(props) {
  return (
    <div className='HistoryScrollView'>
      {props.children}
    </div>
  );
}

function ConfirmDeleteModal(props) {
  return (
    <div onClick={props.handleDeleteFalse} className="ConfirmDeleteModalBackground">
      <div className="ConfirmDeleteModal">
        <p class="title">Confirm</p>
        <p class="subtitle">Delete entry for <strong>{props.distance } KMs</strong> on <strong>{ props.day }</strong>?</p>
        <div>
          <button className='DeleteConfirmYesBtn' onClick={props.handleDeleteTrue}>YES</button>
          <button className='DeleteConfirmNoBtn' onClick={props.handleDeleteFalse}>NO</button>
        </div>
      </div>
    </div>
  )
}

function HistoryPage(props) {
  const [data, updateData] = useState(getOrInitialiseStorage().data);
  const [popup, setPopup] = useState({
    show: false,
    id: null,
    day: null,
    distance: null
  });
  
  function handleDeleteTrue() {
    deleteDistance(popup.id)
    setPopup({
      show: false,
      id: null,
      day: null,
      distance: null
    })
  }

  function handleDeleteFalse() {
    setPopup({
      show: false,
      id: null,
      day: null,
      distance: null
    })
  }

  function showConfirmation(e) {
    setPopup({
      show: true,
      id: e.currentTarget.dataset.index,
      day: e.currentTarget.dataset.day,
      distance: e.currentTarget.dataset.distance
    })
  }

  function deleteDistance(id) {
    const session = removeDistance(id);
    const newData = session.data;
    updateData(newData);
  }
  
  function getDateDisplay(date) {
    let parts = date.split('-');
    return parts[2] + " " + months[parseInt(parts[1]) - 1] + ", " + parts[0];
  }

  return (
    <Page className="HistoryPage" data-name="history">
      { popup.show && <ConfirmDeleteModal 
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
          day={popup.day}
          distance={popup.distance}
      /> }
      <HistoryScrollView>
        <HistoryContainer>
          {
            [
              ...Object.entries(data).map((d) => {
                let index = d[0];
                let point = d[1];
                return <HistoryItem 
                  key={index}
                  index={index}
                  onClickDelete={showConfirmation}
                  day={getDateDisplay(point.date)}
                  distance={point.distance}
                />
              })
            ].sort((a, b) => {
              let dateA = Date.parse(a.props.day);
              let dateB = Date.parse(b.props.day);
              if (dateA > dateB) {
                return -1;
              } else if (dateA === dateB) {
                return 0;
              } else {
                return 1;
              }
            })
          } 
        </HistoryContainer>
      </HistoryScrollView>
    </Page>
  );
}

function getOrInitialiseStorage() {
  let sessionJSON = localStorage.getItem('cycling-tracker-session');
  if (sessionJSON) {
    let session = JSON.parse(sessionJSON);
    return session;
  } else {
    let session = {
      user: {
        username: null,
        token: null
      },
      data: {},
      metadata: {
        lastest_id: 0,
      },
    }
    updateSession(session)
    return session;
  }
}

function updateSession(session) {
  let sessionJSON = JSON.stringify(session);
  localStorage.setItem('cycling-tracker-session', sessionJSON);
}

function addDistance(date, distance) {
  let session = getOrInitialiseStorage();
  const sessionDateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  let new_id = session.metadata.lastest_id + 1;
  
  session.data[parseInt(new_id)] = {
    date: sessionDateStr,
    distance: distance
  }
  session.metadata.lastest_id = new_id;
  updateSession(session);
  gtag("event", "earn_virtual_currency", {
    virtual_currency_name: "kms",
    value: distance
  });
  return session;
}

function removeDistance(id) {
  let session = getOrInitialiseStorage();

  delete session.data[id];
  updateSession(session);
  return session;
}

function syncData() {
  // Use username and token to send data to server
}

function loginUser() {
  // Set username and token
}

function logoutUser() {
  // Sync data
  // Remove username and token
}

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [showAddedToast, setShowAddedToast] = useState(null);

  useEffect(() => {
    getOrInitialiseStorage();
  }, []);

  

  function getPage(currentPage) {
    switch (currentPage) {
      case 'landing': 
        return <LandingPage />;
      case 'signup':
        return <SignupPage />;
      case 'login':
        return <LoginPage />;
      case 'home':
        return <HomePage />;
      case 'track':
        return <TrackPage />;
      case 'history':
        return <HistoryPage />;
      case 'profile':
        return <ProfilePage />;
    }
  }

  return (
    <div className="App App-grey">
      <AppContext.Provider value={{
          setShowAddedToast: setShowAddedToast,
          showAddedToast: showAddedToast,
          currentPage: currentPage,
          setCurrentPage: setCurrentPage
        }}>
        <AppContext.Consumer>
          { value => <AddedToast /> }
        </AppContext.Consumer>
        <div className='Content'>
          {getPage(currentPage)}
        </div>
        </AppContext.Provider>
      <Navbar page={currentPage} onclick={setCurrentPage} />
    </div>
  );

}

export default App;
